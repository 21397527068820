@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Open+Sans:wght@300;400;500;600;700&display=swap');

* {
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
}

body {
    background-color: #3d5a80;
}

.page-description {
    color: white;
}

.logged-in-name {
    color: white;
}

.name {
    color: white;
    font-weight: bold;
    padding-left: 10px;
}

.registration {
    color: white !important;
}

.boxTitle {
    text-align: center;
    font-weight: bold;
}

.logo {
    width: 100px;
    padding-left: 10px;
}

.blueLogo {
    width: 150px;
    margin-bottom: 25px;
}

.cardTitle {
    font-size: 24px;
    margin: 0;
    font-weight: bold;
}

.card {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
    min-height: 100px;
}

.checkTable {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    padding-bottom: 5px;
    border-bottom: 1px solid lightgrey;
}
.MuiInputBase-input{
    padding:10px 5px !important;
}
#standard-basic-helper-text{
    padding-left:14px;
}


@media screen and (max-width: 580px) {
    .homeWrapper{
		width: 100% !important;
    }
    .card-option{
        width:100% !important;
    }
}

.filteredData{
    background-color: #fff;
    display:flex;
    flex-direction: column;
    width: 600px;
    overflow-y: scroll;
    position: absolute;
    padding: 15px;
}

.filteredData-Title{
	font-weight: bold;
}
ul {
    list-style-type: none;
    padding: 0px;
    margin: 0px;
}
li {
    padding-top:10px;
}

.checkTable{
    padding-bottom:10px;
}